export default class FDivision {
  constructor(
      id,
      sourceId,
      kode1,
      kode2,
      description,
      fcompanyBean,
      statusActive,
      webServiceActive,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.sourceId = sourceId;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.fcompanyBean = fcompanyBean;
    this.statusActive = statusActive;        // Boolean
    this.webServiceActive = webServiceActive; // Boolean
    this.created = created;                   // Date
    this.modified = modified;                 // Date
    this.modifiedBy = modifiedBy;             // String
  }
}
