export default class FCompany {
  constructor(
      id,
      kode1,
      kode2,
      description,
      shareDataToBeClone,
      shareDataToBeCloneSecurityCode,
      statusActive,
      created,
      modified,
      modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.kode2 = kode2;
    this.description = description;
    this.shareDataToBeClone = shareDataToBeClone; // Boolean
    this.shareDataToBeCloneSecurityCode = shareDataToBeCloneSecurityCode;
    this.statusActive = statusActive;            // Boolean
    this.created = created;                      // Expected as Date
    this.modified = modified;                    // Expected as Date
    this.modifiedBy = modifiedBy;
  }
}